.campaign-container {
  box-sizing: border-box;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  max-width: 861px;
  padding-top: 10px;
  padding-right: 31.5px;
  padding-left: 31.5px;
  background: rgba(255, 255, 255, 1);
  border: 1px solid rgba(237, 237, 238, 1);
  border-radius: 16px;
  box-shadow: 0px 1px 2px rgba(19, 15, 38, 0.04);
}
.flex-container {
  display: flex;
  flex: 0 0 auto;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-end;
  margin-right: -21.5px;
  margin-left: -21.5px;
}
.container-with-svg {
  flex: 0 0 auto;
  padding-top: 22px;
  margin-right: -2px;
}
.gradient-background-container {
  box-sizing: border-box;
  width: 797px;
  height: 300px;
  background: linear-gradient(0deg, rgba(19, 15, 38, 0.08), rgba(19, 15, 38, 0.08)),
    url(https://s3-alpha-sig.figma.com/img/a1c5/5315/cd172afa1f74670552d60b7d4b6e201d?Expires=1699833600&Signature=GbtOAEOBSjeNGzc5-XqtDq5zvCXEYYffq0hUxCEFLb54v1kGH-KhhAiCH5KfDb2icrdBUhXWa~GQfmOtLuEssMFYvDfY98n6~PjdStGk~NTCgq8yf5PyT3gHIkb9EDWdxKZDY4qWFwMCrl9zG5cTh2A35uMityvYr2Bs8ysedxpjShoFwinxYIOndiZ83eW3wVN0CXoiJFhmdV75EOXjNCtbyQbnuz86lsw4vx4sOVgrvvimLh-vVC9XVIPqWf7FvfsWLujNHhbXTy96RZVlRyny5qsZeY8Ej~~~L54NbTYtkVPk2ugPFirOC9RBmMvSR6d-VZbWu55TpyQAiG3Kug__&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4);
  background-repeat: no-repeat, no-repeat;
  background-size: auto, cover;
  border-radius: 8px;
}
.svg-container4 {
  flex: 0 0 auto;
  padding-bottom: 298px;
}
.svg-container {
  display: flex;
  width: 24px;
  height: 24px;
  color: rgba(19, 15, 38, 1);
}

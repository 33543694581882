.email-sent-container2 {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-width: 600px;
  padding: 48px;
  background: rgba(255, 255, 255, 1);
  border: 1px solid rgba(237, 237, 238, 1);
  border-radius: 19px;
  box-shadow: 0px 1px 2px rgba(19, 15, 38, 0.04);
}
.email-sent-container1 {
  box-sizing: border-box;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  align-items: stretch;
  justify-content: center;
  height: 100px;
  padding-right: 30px;
  padding-left: 30px;
  background: rgba(85, 52, 165, 1);
  border-radius: 50%;
  opacity: 0.15000000596046448;
}
.image-container {
  box-sizing: border-box;
  display: block;
  flex: 0 0 auto;
  width: 40px;
  max-width: initial;
  height: 32px;
}
.img-content-37065d85 {
  content: url("data:image/svg+xml;charset=utf8,%3Csvg%20viewBox%3D%220%200%2040.99950000000001%2032.058749999999975%22%20x%3D%220%22%20y%3D%220%22%20fill%3D%22none%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Cpath%20id%3D%22Vector%22%20d%3D%22M15.8167%2C30.96073c-0.382%2C0.384%20-0.903%2C0.598%20-1.444%2C0.598c-0.541%2C0%20-1.062%2C-0.214%20-1.444%2C-0.598l-11.531%2C-11.533c-1.197%2C-1.197%20-1.197%2C-3.137%200%2C-4.332l1.443%2C-1.444c1.198%2C-1.197%203.136%2C-1.197%204.333%2C0l7.199%2C7.199l19.453%2C-19.453c1.197%2C-1.197%203.138%2C-1.197%204.332%2C0l1.444%2C1.444c1.197%2C1.197%201.197%2C3.137%200%2C4.332z%22%20fill%3D%22white%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%2F%3E%3C%2Fsvg%3E");
}

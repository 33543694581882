.password-reset-form {
  display: flex;
  flex-direction: column;
  gap: 40px;
  align-items: stretch;
  justify-content: flex-start;
  padding: 48px;
  background: rgba(255, 255, 255, 1);
  border: 1px solid rgba(237, 237, 238, 1);
  border-radius: 19px;
  box-shadow: 0px 1px 2px rgba(19, 15, 38, 0.04);
}
.password-input-container {
  box-sizing: border-box;
  flex: 0 0 auto;
  flex-direction: row;
  align-items: center;
  justify-content: start;
  height: 81px;
  border: none;
}
.password-label {
  display: block;
  margin-bottom: 10px;
  font: 400 16px Work Sans;
  color: rgba(19, 15, 38, 1);
}
.password-container {
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  max-width: 504px;
  height: 48px;
  background: rgba(255, 255, 255, 1);
  border: 1px solid rgba(237, 237, 238, 1);
  border-radius: 6px;
}
.input-container {
  box-sizing: border-box;
  width: 100%;
  margin-left: 16px;
  font: 400 16px Work Sans;
  background: none transparent;
  border: none;
  outline: none;
}
.input-style-f62::placeholder {
  color: rgba(150, 145, 161, 1);
}
.svg-container {
  display: flex;
  width: 24px;
  height: 24px;
  margin-top: 10px;
  margin-right: 15px;
  margin-bottom: 14px;
  color: rgba(19, 15, 38, 1);
}
.sign-in-button {
  box-sizing: border-box;
  display: block;
  flex: 0 0 auto;
  width: 504px;
  height: 48px;
  font: 500 18px Work Sans;
  color: rgba(255, 255, 255, 1);
  cursor: pointer;
  background: linear-gradient(90.44deg, #7a48f3 131.89%, #391989 7.68%);
  border: none;
  border-radius: 8px;
}

.protest-container2 {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  min-width: 1440px;
  padding-top: 48px;
  padding-bottom: 242px;
  background: rgba(246, 243, 252, 1);
}
.protest-container {
  display: flex;
  flex: 0 0 auto;
  flex-direction: row;
  align-items: center;
  align-self: flex-start;
  justify-content: flex-start;
  padding-right: 48px;
  padding-left: 49px;
}
.protest-container1 {
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}
.image-container {
  box-sizing: border-box;
  display: block;
  flex: 0 0 auto;
  align-self: stretch;
  width: 47px;
  max-width: initial;
  height: 57px;
}
.img-content-9f6aa9a3 {
  content: url("data:image/svg+xml;charset=utf8,%3Csvg%20viewBox%3D%220%200%2047.6418%2057%22%20x%3D%220%22%20y%3D%220%22%20fill%3D%22none%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Cpath%20id%3D%22Vector_2%22%20d%3D%22M40.0706%2C18.4286c-0.3922%2C-0.3625%20-0.9425%2C-0.5272%20-1.481%2C-0.443c-0.5395%2C0.0839%20-1.0053%2C0.406%20-1.2556%2C0.8682c-0.8607%2C1.5903%20-1.9539%2C3.0516%20-3.2402%2C4.3434c0.1283%2C-0.9331%200.1928%2C-1.8719%200.1928%2C-2.8132c0%2C-1.8061%20-0.2545%2C-3.6647%20-0.7567%2C-5.5249c-1.6513%2C-6.1098%20-5.9812%2C-11.2767%20-11.8793%2C-14.1759c-0.5135%2C-0.2524%20-1.1253%2C-0.2435%20-1.6305%2C0.0236c-0.5053%2C0.2672%20-0.8361%2C0.7571%20-0.8814%2C1.305c-0.4598%2C5.5524%20-3.467%2C10.6037%20-8.255%2C13.8622c-0.0634%2C0.0435%20-0.1263%2C0.0874%20-0.1892%2C0.1312c-0.1303%2C0.0906%20-0.2531%2C0.1764%20-0.3679%2C0.2484c-0.0179%2C0.0113%20-0.0358%2C0.0229%20-0.0532%2C0.0349c-3.0113%2C2.0504%20-5.496%2C4.7837%20-7.1858%2C7.9051c-1.717%2C3.1751%20-2.5876%2C6.5822%20-2.5876%2C10.1265c0%2C1.8056%200.2545%2C3.6642%200.7565%2C5.5247c2.6491%2C9.8062%2011.9282%2C16.6552%2022.5651%2C16.6552c12.8588%2C0%2023.3202%2C-9.95%2023.3202%2C-22.1799c0%2C-6.0309%20-2.5113%2C-11.6747%20-7.0712%2C-15.8915z%22%20fill%3D%22%235534A5%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%2F%3E%3C%2Fsvg%3E");
}
.svg-container {
  display: flex;
  flex: 0 0 auto;
  width: 32px;
  height: 32px;
  margin-top: -37px;
  color: rgba(255, 255, 255, 1);
}
.virtual-protest-title {
  flex: 0 0 auto;
  margin-left: 10px;
  font: 600 31px Work Sans;
  color: rgba(19, 15, 38, 1);
}
.password-reset-container {
  box-sizing: border-box;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  align-items: stretch;
  justify-content: center;
  max-width: 600px;
  padding: 48px 48px 48px 47px;
  margin-top: 138px;
  background: rgba(255, 255, 255, 1);
  border: 1px solid rgba(237, 237, 238, 1);
  border-radius: 19px;
  box-shadow: 0px 1px 2px rgba(19, 15, 38, 0.04);
}

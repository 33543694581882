.protest-account-info {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  min-width: 1440px;
  max-width: 1440px;
  padding: 32px 108px 44px;
}

.main-container {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  min-width: 1440px;
  max-width: 1440px;
  padding: 32px 108px 22px;
}
.participant-section1 {
  display: flex;
  flex: 0 0 auto;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}
.container-with-svg3 {
  box-sizing: border-box;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 36px;
  height: 36px;
  background: rgba(255, 255, 255, 1);
  border: 1px solid rgba(85, 52, 165, 1);
  border-radius: 6px;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
}
.svg-container {
  display: flex;
  flex: 0 0 auto;
  width: 20px;
  height: 20px;
  color: rgba(85, 52, 165, 1);
}
.home-link {
  flex: 0 0 auto;
  margin-left: 8px;
  font: 500 16px Work Sans;
  color: rgba(85, 52, 165, 1);
}

.button-map:hover { 
  box-shadow: -2px -2px 6px rgba(255, 255, 255, .6), -2px -2px 4px rgba(255, 255, 255, .4), 2px 2px 2px rgba(255, 255, 255, .05),2px 2px 4px rgba(0, 0, 0, .1);
}
